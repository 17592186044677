body {
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0;
  background-color: #f4f4f4;
  padding: 2rem;
}

.container-style {
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.question-container-style {
  text-align: center;
}

.question-style {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button-style {
  font-size: 18px;
  padding: 10px 20px;
}

.score-container {
  text-align: center;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.score-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  margin: 0 auto 20px auto;
}

.score-text {
  font-size: 18px;
  color: #333;
}

.help-text {
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}

.decision-text {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.restart-button {
  display: block;
  margin: 20px auto; /* This will center the button horizontally */
  padding: 10px 20px; /* Add padding for a nicer appearance */
  cursor: pointer;
  text-align: center;
}

.restart-button:hover {
  background-color: #0056b3; /* Change background color on hover */
}