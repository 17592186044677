@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap");

.form-header {
  margin: 1.5rem auto;
  h4 {
    margin: 0;
  }
}

.form-container {
  text-align: left;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  margin: 0 auto; /* Center the form */
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0rem 1rem;
}

.reset-password {
  color: teal;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.required {
  color: red;
}

.terms-agree {
  font-size: 18px;
}

.terms-link {
  cursor: pointer;
  font-weight: 600;
  color: teal;
}

.terms-container {
  font-family: "Open Sans", sans-serif;
  padding: 40px;
  margin: 0 auto;
  max-width: 60%;
  text-align: left;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.terms-container h1,
.terms-container h2,
.terms-container h3,
.terms-container h4,
.terms-container h5,
.terms-container h6 {
  font-family: "Quicksand";
}

.terms-sign {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;

  p {
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.terms-line {
  border-bottom: 1px solid black;
  margin-top: 2.5rem;
  width: 150px;
}

.terms-sign-container {
  border: 1px solid black;
  padding: 1rem;
  padding-bottom: 1.5rem;
  margin: 1rem 0;
}
