@import "./variables.scss";

//use fonts: s
//font-family Open Sans
// - font weight: 400/regular, 700/bold
// itallic ** Only with 400/regular

//font-family Quicksand
// -font-weight: 700/bold ONLY

// Theme overwrite:
$theme-colors: (
  "primary": $primary,
  "danger": #ff4136,
  "alert": #e5ab40,
  "info": $teal,
  "gray": $gray,
);

$info: $teal;
$light: #e0f3f1;
$body-bg: $main-bg;
$body-color: $black;

$logo-light: url("./images/Logo.png")
  // // Used for removing the up/down arrows in a num field.
;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import "~bootstrap/scss/bootstrap.scss";

h1,
h2,
h3,
h4,
h5,
h6,
header {
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body.dark-mode {
  background-color: $dark-mode-bg;
  color: $dark-mode-text;
  .nav-link {
    color: $gray !important;
  }
  .nav-link.active,
  .nav-link.active:hover {
    color: $dark-mode-text !important;
  }
  .nav-link:hover {
    color: $dark-mode-primary !important;
  }
  .componentWrapper {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .sallie {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .tooltip-text {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .bubbleText {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .sc-gswNZR {
    background-color: $dark-mode-component !important;
    color: $dark-mode-text !important;
  }
  .choseList {
    background-color: $dark-mode-component !important;
    color: $dark-mode-text !important;
  }
  .navLink {
    background-color: $dark-mode-component !important;
    color: $dark-mode-text !important;
    border: 2px solid $dark-mode-text !important;
  }
  .submitButton {
    color: $dark-mode-text;
    border: 2px solid $dark-mode-text;
  }
  .submitButton:hover {
    background-color: $dark-mode-primary;
  }
  .logo {
    width: 258px;
    height: 100px;
  }
  #nav-border {
    border-bottom: 4px solid $dark-mode-primary !important;
  }

  .pillBtn {
    background-color: $dark-mode-primary;
    color: $dark-mode-text;
  }
  .listSelector {
    background: $dark-mode-component;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: $gray;
  }
  .DayPicker-Day--disabled {
    color: $dark-mode-disabled;
  }
}

.componentWrapper {
  background-color: white;
  border-radius: 5px;
  border: 2px solid $gray;
  // Set top and bottom padding equal to what Bootstrap providers for left and right padding on container spacing
  padding: 15px;
}

.sallie {
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 10px;
  padding: 1rem;
  border-radius: 10px;
  background: white;
  border: 2px solid $gray;
}
.bubbleText {
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 10px;
  padding: 1rem;
  border-radius: 10px;
  background: rgb(255, 254, 209);
  border: 2px solid $gray;
}

.submitButtonBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.progress {
  width: 35%;
  border-radius: 20px;
}
#nav-border {
  border-bottom: 4px solid $primary !important ;
}

.listSelector {
  background: white;
  border-radius: 5px;
  border: 2px solid $primary;
  padding: 0.5rem;
  margin: 0.25rem 0;
}
// see node-modules/react-day-picker/src/styles.css

.DayPicker {
  display: inline-block;
  width: 100%;
  font-size: 1rem !important;
}
@media (min-width: 576px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .DayPicker {
    font-size: 0.8rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}
.required {
  color: red;
}
//remove red icon from invalid forms
.form-control.is-invalid {
  padding-right: inherit;
  background-image: none;
}
// stop shifting of form on invalid messages
.form-group {
  margin-bottom: 30px;
}
.invalid-feedback {
  height: 0;
}

.logo {
  max-height: inherit;
  object-fit: cover;
}

.brand-wrapper {
  max-height: 100px;
}

a {
  color: $teal;
}

.h6-label {
  @extend h6;
}

.accountSettingNav {
  width: 250px;
  height: 350px;
  border-radius: 5px;
  border: 2px solid $gray;
  padding: 1rem;
  margin: 15px 0px;
}

.nav-link {
  color: $black;
}

.nav-link:hover {
  color: $teal;
}

.goalForm {
  width: 700px;
  margin-top: 15px;
}

.goalList {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

.goalItem {
  margin-bottom: 5px;
}

.tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}
.tab-content > .tab-pane.active {
  height: auto;
}

.helpImage {
  position: absolute;
  z-index: 100;
  margin-left: 5px;
  width: 450px;
  height: 300px;
}

.headacheForm {
  margin: 5px;
}

.editIcon {
  height: 15px;
  width: 15px;
}
.report-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.pdf-option-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  color: #333;
  text-decoration: none;
  margin: 2rem 0;
}

.patient-status .Dropdown-root {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.patient-status .Dropdown-control {
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #f7bc12;
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  outline: none;
  padding: 0.5rem 1rem;
  transition: all 200ms ease;
}

.patient-status .Dropdown-arrow {
  border-color: white transparent transparent;
}

.green {
  background-color: #3eb1a6 !important;
}

.red {
  background-color: #ef5866 !important;
}
.gray {
  background-color: #b7b7b7 !important;
}

.membership-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  min-width: 50%; /* Adjust as needed */
  margin: 20px auto; /* Center the card horizontally */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.description li {
  margin: 1rem;
}

.dates {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.date-item {
  display: flex;
  margin-left: 1rem;
}

.date-item h5 {
  margin-right: 5px;
}

.price {
  margin-right: 5px;
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

//modal

.custom-modal .modal-content {
  max-width: 800px;
  margin: auto; /* Center the modal horizontally */
  transform: translateY(30%); /* Adjust the value to move the modal lower */
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 70%;
    margin: 1.75rem auto;
  }
}
.modal-scroll {
  max-height: 400px;
  overflow-y: auto; /* Add vertical scroll when content overflows */
}

//invoice-card

.invoice-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2px;
  margin: 20px 0;
}

.invoice-container {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
}

.invoice-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.invoice-container p {
  font-size: 16px;
  margin: 8px 0;
}

/* Style for specific invoice statuses */
.invoice-container p.status {
  font-weight: bold;
  color: #00a000; /* Green color for 'Paid' status */
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  // background-color: $dark-mode-component;
  // border: 2px solid $dark-mode-text;
  border-radius: 10px;
  background: white;
  border: 2px solid $gray;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
