// $primary: #3d2566;
$primary:  #106995;//change purple to blue
$teal: #3eb1a6;
$main-bg: #f4f3ee;
$black: #212121;
$gray: #b7b7b7;
$pink: #ef5866;
$dark-mode-bg: #302d3b;
$dark-mode-component: #4d4a58;
$dark-mode-text: #f3f0ff;
// $dark-mode-primary: #643288;
$dark-mode-primary: #106995;
// $dark-mode-disabled: #7a768b;
$dark-mode-disabled: #106995;
$chart1: #649e3e;
$chart2: #9e7334;
$chart3: #38ebc9;
$chart4: #1e9e86;
$chart5: #eb444f;
$chart6: #8deb50;
$chart7: #eba642;
$chart8: #3743eb;
$chart9: #9e9d15;
$chart10: #ebea2a;

$dark-teal: scale-color($teal, $lightness: -30%);
:export {
  darkTeal: $dark-teal;
}
:export {
  gray: $gray;
}
:export {
  pink: $pink;
}
:export {
  primary: $primary;
}
:export {
  teal: $teal;
}
:export {
  main-bg: $main-bg;
}
:export {
  black: $black;
}
:export {
  darkModeBg: $dark-mode-bg;
}
:export {
  darkModeComp: $dark-mode-component;
}
:export {
  darkModeText: $dark-mode-text;
}
:export {
  darkModePrimary: $dark-mode-primary;
  chart1: $chart1;
}
:export {
  chart2: $chart2;
}
:export {
  chart3: $chart3;
}
:export {
  chart4: $chart4;
}
:export {
  chart5: $chart5;
}
:export {
  chart6: $chart6;
}
:export {
  chart7: $chart7;
}
:export {
  chart8: $chart8;
}
:export {
  chart9: $chart9;
}
:export {
  chart10: $chart10;
}
